<template>
  <div>
    <button class="floating-button" @click="toggleWireframe">
      <img
        v-if="!isOpen"
        :src="superAgentesIcon"
        alt="Abrir"
        class="button-icon"
      />
      <span v-else>✖</span>
    </button>

    <div v-if="isOpen">
      <iframe
        src="https://dash.superagentes.ai/agents/cm57fd8d900jnftsglbif4otr/iframe"
        style="
          position: absolute;
          bottom: 130px;
          right: 20px;
          width: 400px;
          height: 80vh;
          z-index: 9999;
          border: none;
        "
        allow="clipboard-write"
      />
    </div>
  </div>
</template>

<script>
import superAgentesIcon from 'assets/images/super-agentes.png';
export default {
  data() {
    return {
      isOpen: false,
      superAgentesIcon,
    };
  },
  methods: {
    toggleWireframe() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.floating-button {
  position: absolute;
  bottom: 60px;
  right: 20px;
  background: #007bff;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
.button-icon {
  width: 26px;
  height: 30px;
}
</style>
